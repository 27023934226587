import { ProcessMap } from "../../models/process-map.model";
import { stateMap } from "../../state.map";

export class CollaboratorStateMap {

    static orderStateMap: { [key: string]: ProcessMap } = {
        '': {
            ...stateMap.NEW,
            nextState: 'PROCESSING',
            nextStates: [
                stateMap.PROCESSING,
            ],
        },
        NOTJUSTAPPROVED: {
            ...stateMap.NEW,
            id: 'NOTJUSTAPPROVED',
            state: 'NOTJUSTAPPROVED',
            nextState: 'PROCESSING',
            nextStates: [
                stateMap.PROCESSING,
            ],
        },
        PROCESSING: {
            ...stateMap.PROCESSING,
            nextState: 'APPROVED',
            nextStates: [
                { ...stateMap.APPROVED, confirmLabel: 'Chốt đơn', confirmText: 'Bạn có muốn chốt đơn', status: 'success' },
                stateMap.UNRECORDED,
            ],
        },
        APPROVED: {
            ...stateMap.APPROVED,
            label: 'Chốt đơn',
            nextState: 'COMPLETED',
            nextStates: [
                // AppModule.depploymentState,
                // AppModule.transportState,
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        },
        DEPLOYED: {
            ...stateMap.DEPLOYED,
            nextState: 'COMPLETED',
            nextStates: [
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        },
        COMPLETED: {
            ...stateMap.COMPLETED,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED,
            ],
        },
        RETURN: {
            ...stateMap.RETURN,
            nextState: 'COMPLETED',
            nextStates: [
                // { ...AppModule.completeState, status: 'success' },
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        },
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'PROCESSING',
            nextStates: [
                stateMap.PROCESSING,
            ],
        },
    };

    static opportunityStateMap: { [key: string]: ProcessMap } = {
        '': {
            ...stateMap.NEW,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.FREEZE,
            ],
        },
        NOTJUSTAPPROVED: {
            ...stateMap.NEW,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.FREEZE,
            ],
        },
        NEW: {
            ...stateMap.NEW,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.FREEZE,
            ],
        },
        FREEZE: {
            ...stateMap.FREEZE,
            nextState: 'COOL',
            nextStates: [
                stateMap.COOL,
                stateMap.UNRECORDED,
            ],
        },
        COOL: {
            ...stateMap.COOL,
            nextState: 'WARM',
            nextStates: [
                stateMap.WARM,
                stateMap.UNRECORDED,
            ],
        },
        WARM: {
            ...stateMap.WARM,
            nextState: 'HOT',
            nextStates: [
                stateMap.HOT,
                stateMap.UNRECORDED,
            ],
        },
        HOT: {
            ...stateMap.HOT,
            nextState: 'CONVERTED',
            nextStates: [
                stateMap.CONVERTED,
                stateMap.UNRECORDED,
            ],
        },
        CONVERTED: {
            ...stateMap.CONVERTED,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.UNRECORDED,
            ],
        },
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.FREEZE,
            ],
        },
    };
}
